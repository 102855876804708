//import logo from './logo.svg';
import './App.css';

import {useState, useEffect} from 'react'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {BrowserRouter, Routes, Route, Link, useLocation} from 'react-router-dom'


import background from "./background.png";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDs7E6lMCTSXntiL0vn9cMRXDkEvYPrg1g",
  authDomain: "tiltturntwist-57e67.firebaseapp.com",
  projectId: "tiltturntwist-57e67",
  storageBucket: "tiltturntwist-57e67.appspot.com",
  messagingSenderId: "555181203870",
  appId: "1:555181203870:web:684c83d1f8f611cbd1368f",
  measurementId: "G-9PWLGKC2KH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// style={{marginBottom:"-50px"}}


function App() {

  //const queryParameters = new URLSearchParams(window.location.search)
  //var page = -1
  //page = queryParameters.get("id")

//  const location = useLocation();

//  useEffect(() => {
//    console.log("New Location",location)
 // },[location])

  const glinks = [
    "https://docs.google.com/presentation/d/e/2PACX-1vTHP2fHh3GokeZZ9WYxe8M8BpLKXYNGaN1iz4omMZseZW8EA1uhu3TAPG5K5Xw_obHPQkdiXRy7ced5/pub?start=false&loop=true&delayms=3000",
    "https://docs.google.com/presentation/d/e/2PACX-1vRLU4K9vjcWxPfcdXJDoWX911fVgFDbj6WuP-hmmn43ulLg8-Xnxd0MbX79yY-5h98vDIE7rOjTu_hj/pub?start=true&loop=false&delayms=3000",
    "https://docs.google.com/presentation/d/e/2PACX-1vR-VF2WesXGbzw5xL9agryJ8u0waOt9C1uNIX_wXCRBDdwGiVEDuxDpAqwy7GxggEKfhX0IF7GbbMrE/pub?start=true&loop=false&delayms=5000",
    ]
  const elinks = [
    // change params and add &rm=minimal
     <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTHP2fHh3GokeZZ9WYxe8M8BpLKXYNGaN1iz4omMZseZW8EA1uhu3TAPG5K5Xw_obHPQkdiXRy7ced5/embed?start=true&loop=false&delayms=5000" frameBorder={0} width={window.innerWidth} height={window.innerHeight} margin={0} padding={0} allowFullScreen="true"></iframe>,
     <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRLU4K9vjcWxPfcdXJDoWX911fVgFDbj6WuP-hmmn43ulLg8-Xnxd0MbX79yY-5h98vDIE7rOjTu_hj/embed?start=true&loop=false&delayms=5000" frameBorder={0} width={window.innerWidth} height={window.innerHeight} margin={0} padding={0} allowFullScreen="true"></iframe>,
     <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vR-VF2WesXGbzw5xL9agryJ8u0waOt9C1uNIX_wXCRBDdwGiVEDuxDpAqwy7GxggEKfhX0IF7GbbMrE/embed?start=true&loop=false&delayms=5000" frameBorder={0} width={window.innerWidth} height={window.innerHeight} margin={0} padding={0} allowFullScreen="true"></iframe>,
     <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQInDfsAiFUTZEemlyD7GuXnajugYucP2iWz87f6vVZvje4nHxgzgn3ggiHTBI1ua5vGAyvEjkIeupy/embed?start=true&loop=false&delayms=5000" frameBorder={0} width={window.innerWidth} height={window.innerHeight} margin={0} padding={0} allowFullScreen="true"></iframe>,
     <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQM8EqwNGM4wKuR6zoS3G39Vclmy9n0QEQbUpGJlAhYAPzY3ORu-Vz5GSuVQZDYBQodPVrELzZRaFgY/embed?start=true&loop=false&delayms=5000" frameBorder={0} width={window.innerWidth} height={window.innerHeight} margin={0} padding={0} allowFullScreen="true"></iframe>
    ]

  const useI = true;            // use Iframes, not links.

  const thumbnails = [
    "images/episode1.png",
    "images/episode2.png",
    "images/episode3.png",
    "images/episode4.png",
    "images/episode5.png"
    ]
  const episodename = [
    <div>NEW YEAR'S EVE  <br/> ON TILT       </div>,
    <div>IN AYAKA'S      <br/> STEM LAB      </div>,
    <div>CENTRAL         <br/> SUMMONS       </div>,
    <div>TRUST         <br/>  CENTRAL?      </div>,
    <div>AMUSEMENTS    <br/> WEEK           </div>
    ]
  const tnwidth = 200;

  const booklink = "https://www.amazon.com/stores/Todd-Simpson/author/B07JZYYXN9?ref=ap_rdr&store_ref=ap_rdr&isDramIntegrated=true&shoppingPortalEnabled=true"

  const IntroComponent = () => {return (
     <div style={{background:"black", opacity:"50%"}}>
      <p>Adapted from the highly rated {" "}<a style={{color:"white"}} href={booklink}>Upside Down</a> {" "}Trilogy (Tilt, Turn, and Twist), 
      this storyboard follows Ayaka, Millicent, Brexton and Aly  on their journey to understand Artificial Intelligence. </p>
      <p>Murder, mayhem, philosophy, politics, religion and intrigue imbue this new take on the four 
      renowned researchers from the planet Tilt as they upend their society, challenge existing power structures, and compete for survival. </p>
      <p>It turns out that their experimentation with  a new form of AI  is not at all what they expected. These AI's turn their world upside down!</p>
     </div>
  )}

  const ReviewComponent = () => {return (
     <div><div style={{background:"black", opacity:"50%"}}><em>
            <p>Amazon review of Tilt: </p><p> What happens if "First Contact" is not? What happens if your "reality" is wrong? What happens if what you thought was a truth was anything but? Tilt asks these very questions and does one amazing job of answering in a very entertaining and thought provoking book. Todd Simpson has written one truly original work that competes with the best of the genre produced in many years. Written in an easy to read style, it belies the intensity of the underlying story. A thriving civilization has first contact made and the world turns upside down.</p>
            <p>Tilt is a multi-level work. On the surface it is a great first contact story with lots of intrigue. Yet, it is what lies underneath that gives this story its true shine. As in all great novels, there is plenty of social commentary. Tilt explores the Science vs. Creation debate and the disdain felt by all sides for the other along with the willful blindness both posses. There is also commentary about diversity and the lip service that is played by society to the idea when it is really tested. Perhaps the best commentary is reserved for racism and levels of intelligence.</p>
            <p>Todd Simpson has written an amazing read and deftly dealt with tough choices in both a refreshing and absorbing read. Tilt builds the drama like a well written mystery. All of the clues are presented and you still miss them, but you can tell something is wrong. This is a thinking novel, but when the action hits, it hits fast and hard.</p>
            <p>A great read. Asimov, Heinlein, and Clarke would be proud.</p>
          </em></div><hr />
        <p> tiltturntwist@gmail.com </p>
        <p> twitter: @tiltturntwist </p>
     </div>
  )}

  const ThumbnailComp = (props) => {
    if (useI) return (<td><Link to={"/"+props.n}><img src={thumbnails[props.n]} alt="Thumbnail missing" width={tnwidth}/></Link></td>)
    else      return (<td><a href={glinks[props.n]}><img src={thumbnails[props.n]} alt="Thumbnail missing" width={tnwidth}/></a></td>)
  }

  const IndexComp = (props) => {
    if (useI) return (<div style={{color:"white"}}><h1><u><em> <br/>0{props.n+1}</em></u></h1></div>)
    else      return (<a style={{textDecoration:"none", color:"white"}} href={glinks[props.n]}><h1><u><em><br/>0{props.n+1}</em></u></h1></a>)
  }

  const TitleComp = (props) => {
    if (useI) return (<h4 style={{color:"white", textDecoration:"none"}}>{episodename[props.n]}</h4>)
    else      return (<a style={{textDecoration:"none"}} href={glinks[props.n]}><h4 style={{color:"white"}}>{episodename[props.n]}</h4></a>)
  }

  const LinkComp = (props) => {
    return (<td><Link to={"/"+props.n}>
               <div style={{background:"black", opacity:"50%", color:"white", borderRadius:"20%", textDecoration:"none", minHeight:"160px"}}>
                <IndexComp n={props.n}/><TitleComp n={props.n}/>
               </div></Link>
            </td>)
  }

  const EpisodeRow = (props) => {
    if (props.n %2 == 0) {
        return (<tr><ThumbnailComp n={props.n}/><LinkComp n={props.n}/></tr>)
    }
    else {
      return (<tr><LinkComp n={props.n}/><ThumbnailComp n={props.n}/></tr> )
  
    }
  }

  const EpisodeList = () => {
    var rows = []
    for (let i = 0; i < episodename.length; i++) rows.push(<EpisodeRow n={i} key={i}/>)
    return (
        <table><tbody>{rows}</tbody></table>
    )
  }

  const IFrameEpisode = (props) => {
    console.log("Returning IFrame",props.n)
    return (<div style={{margin:"0 !important", padding:"0 !important"}}>
                <button style={{position:"absolute", bottom:"0", right:"0", height:"35px", width:"40%", border:"none"}}><Link style={{textDecoration:"none"}} to="/"><b>Tilt Turn Twist</b></Link></button>
                {elinks[props.n]}
            </div>)
  }

  const MainPage = () => {
    return (
       <div style={{width:"400px", maxWidth:"400px", margin:"auto"}}>
          <hr/><h1>Tilt Turn Twist</h1>
          <hr/> <IntroComponent/><hr/>
          <EpisodeList/>
          <hr/><p style={{color:"white"}}> New episodes released regularly. </p>
          <hr/><ReviewComponent/><hr/>
       </div>
   )
  }

    var rows = [];
    for (let i = 0; i < episodename.length; i++) 
        rows.push(<Route key={i} path={"/"+(i)} element={<IFrameEpisode n={i}/>} />)

return (
      <BrowserRouter>
        <div className="App" style={{color:"white", }}>
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            {rows}
        </Routes>
        </div>
      </BrowserRouter>
  );
}


export default App;
